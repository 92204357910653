html,
body {
  width: 100% !important;
  height: 100% !important;
}

.dropdowntoggle{
  color:white;
}

.dropdown-menu{
  background-color: #036c7b !important;
}

.nav-link{
  color:white;
}

.outer-home {
  width: 90%;
  margin: auto;
  font-family: "Raleway", sans-serif;
  /* padding-left: 300px; */
  /* padding-top: 100px; */
}

.main-logo {
  margin: auto;
  display: block;
  max-width: 80vw;
}

.main-logo-nav {
  margin: auto;
  display: block;
}

.navbar {
  font-family: "Raleway", sans-serif !important;
}

.health-registered{
  display: block;
  width: 135px;
  height: 34px; 
  padding: 8px 0 0 0;
  margin: auto;
  background: url(https://healthcentre.org.uk/images/registered-ukhc.jpg) no-repeat; 
  text-decoration: none; 
  font-size: 10px; 
  color: #fff; 
  font-family:Arial, Helvetica, sans-serif;
  line-height: 9px;
  letter-spacing: 0;
}

.welcome-text {
  max-width: 95%;
  margin: auto;
  margin-top: 5%;
  margin-bottom: 5%;
  text-align: center;
  font-size: 16px;
}

.main-heading {
  margin-bottom: 15px;
  text-align: center;
  font-size: 20px;
  color: #036c7b;
}

.welcome {
  margin-bottom: 20px;
  text-align: center;
  font-size: 26px;
  color: #036c7b;
}

.welcome-left {
  margin-bottom: 20px;
  text-align: left;
  font-size: 26px;
  color: #036c7b;
}

.subheading {
  margin-bottom: 0px;
  text-align: left;
  font-size: 18px;
  color: #036c7b;
  font-weight: bold;
}

.welcome-section {
  margin-top: 20px;
  margin: auto;
}

.condition-section {
  margin-top: 20px;
}

.offcanvas {
  background-color: #036c7b;
  color: white;
}

.offcanvas-title {
  font-family: "Raleway", sans-serif !important;
  font-weight: 800;
  font-size: 24px;
  color: #036c7b;
}

.nav-item {
  font-family: "Raleway", sans-serif !important;
  font-weight: 600;
}

.nav-link {
  font-family: "Raleway", sans-serif !important;
  font-weight: 600;
}

.nav-item:hover {
  color: #0cb5cf !important;
}

.nav-link:hover {
  color: #0cb5cf !important;
}

.dropdown-menu {
  border: none;
}

.dropdown-item:hover {
  background-color: #0cb5cf !important;
  font-weight: 600;
}

.btn-close {
  color: white !important;
}

@media screen and (min-device-width: 620px) {
  .welcome-text {
    font-size: 18px;
  }
}
@media only screen and (min-device-width: 960px) {

  .welcome-text {
    max-width: 80%;
  }

  /* .condition-section {
    width: 85%;
    margin: auto;
  } */
}
@media only screen and (min-device-width: 1200px) {

  .outer-home {
    width: 85%;
    margin: auto;
    padding-left: 300px;
    padding-top: 100px;
  }

  .welcome-text {
    font-size: 20px;
  }

  .main-heading {
    margin-bottom: 25px;
    font-size: 25px;
  }

  .welcome {
    margin-bottom: 23px;
    font-size: 30px;
  }

  .welcome-left {
    margin-bottom: 23px;
    font-size: 30px;
  }

  .welcome-section {
    max-width: 600px;
  }
}
