@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

.App {
  width: 100% !important;
  height: 100% !important;
  background-image: url("./assets/greenbackground.jpg");
  background-repeat: repeat;
  background-size:  auto;
  background-position: top left !important;
  display: flex;
  flex-direction: column;
}
