.header-bar {
  font-family: 'Raleway', sans-serif;
  padding: 15px;
  width: 100%;
  height: 60px;
  background-color: #036c7b;
  color: white;
  text-align: right;
  position: fixed;
  top: 0;
  z-index: -1;
}

.header-text {
  margin: 10px;
  padding: 10px;
  padding-left: 0px;
}

.header-text-email {
  margin: 10px;
  padding: 10px;
  padding-right: 30px;
  padding-left: 0px;
}

@media screen and (min-device-width: 620px) {
  .header-bar {
    padding: 20px;
    z-index: 50;
  }
}
@media only screen and (min-device-width: 960px) {
}
@media only screen and (min-device-width: 1200px) {
}
