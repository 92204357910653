.footer-background {
  position: relative;
  font-family: 'Raleway', sans-serif;
  width: 100%;
  height: 10%;
  background-color: #036C7B;
  margin-top: 40px;
  align-content: center;
  justify-content: center;
}

.flex {
  display: flex;
  text-align: center;
}

.footer-bar {
  display: grid;
  grid-template-columns: 50px auto;
  grid-template-rows: 30px 30px 30px;
  grid-auto-flow: row;
  padding: 15px;
  height: auto;
  align-items: center;
  margin: auto;
}

.logos {
  padding: 10px;
  display: flex;
}

.footer-text {
  font-size: 18px;
  font-weight: bold;
  display: flex;
  justify-self: left;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: white;
}

.disclaimer{
  /* padding-left: 300px; */
  padding-top: 20px;
  width: 80%;
  margin: auto;
  margin-bottom: 50px;
  text-align: center;
  font-weight: bold;
}

@media screen and (min-device-width: 620px) {
}
@media only screen and (min-device-width: 960px) {
  .footer-text {
    font-size: 20px;
  }
}
@media only screen and (min-device-width: 1200px) {
  .disclaimer{
    padding-top: 60px;
    width: 70%;
    padding-left: 300px;
  }
}
