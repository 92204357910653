.accordion-container {
  margin: auto;
  width: 100%;
}

@media screen and (min-device-width: 620px) {
  .accordion-container {
    width: 80%;
  }
}

@media screen and (min-device-width: 1200px) {
  .accordion-container {
    width: 100%;
  }
}
