.spinner-container {
  height: 1000px;
  display: flex;
  align-items: center;
  align-self: center;
  z-index: 50;
}

.card:hover {
  transform: scale(1.02);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 4px 8px rgba(0, 0, 0, 0.06);
}

.card-img-top {
  max-height: 25vh; /*not want to take all vertical space*/
  object-fit: contain;
  margin-top: 20px;
  padding: 1vh;
}

.button-container {
  text-align: center;
  margin-top: 50px;
}
.book {
  margin: auto;
  width: 35%;
  background-color: #0cb5cf;
  color: #036c7b;
  font-weight: bold;
  border: none;
}

.outer-treatments {
  margin: auto;
  font-family: "Raleway", sans-serif;
  text-align: center;
  width: 80%;
}

.menopause-logo {
  margin: auto;
  display: block;
  max-width: 50%;
}

.main-logo-treatments {
  margin: auto;
  display: block;
  max-width: 80%;
}

.treatment-text {
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.treatment-text-2 {
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 0;
}

.treatment-heading {
  margin-bottom: 15px;
  text-align: center;
  font-size: 20px;
  color: #036c7b;
}

.accordion-button {
  font-family: "Raleway", sans-serif;
  font-size: 20px;
  color: #036c7b;
}

.treatment-section {
  text-align: center;
  margin: auto;
  margin-top: 20px;
}

.small-image-container {
  height: 150px;
  width: 150px;
  margin: auto;
  margin-bottom: 20px;
  padding: 10px;
  display: inline-block;
}

.treatment-image {
  height: 100%;
  width: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}

.wide-image-container {
  margin: auto;
  margin-bottom: 15px;
  height: 200px;
  width: 100%;
  padding: 10px;
  display: inline-block;
}

.wide-image-container-mediation {
  margin: auto;
  margin-bottom: 25px;
  width: 320px;
  height: 236px;
  padding: 10px;
  display: inline-block;
}

/* original width 768 x height 1024 - 20%*/
.trees-image-container {
  margin: auto;
  margin-bottom: 15px;
  height: 235.52px;
  width: 176.64px;
  padding: 10px;
  display: inline-block;
}

/* original width 1451 x height 1623 - 20%*/
.oncology-image-container {
  margin: auto;
  margin-bottom: 15px;
  height: 324.6px;
  width: 290.2px;
  padding: 10px;
  display: inline-block;
}

/* original width 1080 x height 1205 - 35%*/
.phone-image-container {
  margin: auto;
  margin-bottom: 15px;
  height: 299.86px;
  width: 268.75px;
  padding: 10px;
  display: inline-block;
}

.ear-phone-image-container {
  margin: auto;
  margin-bottom: 15px;
  height: 193.8px;
  width: 243.5px;
  padding: 10px;
  display: inline-block;
}

.menopause-phone-image-container {
  margin: auto;
  margin-bottom: 15px;
  height: 307px;
  width: 264px;
  padding: 10px;
  display: inline-block;
}

/* original width 1727 x height 2204 - 12%*/
.mfr-image-container {
  margin: auto;
  margin-bottom: 15px;
  height: 264.48px;
  width: 207.24px;
  padding: 10px;
  display: inline-block;
}

/* original width 1612 x height 1264 - 15%*/
.mfr2-image-container {
  margin: auto;
  margin-bottom: 15px;
  height: 181.92px;
  width: 268.8px;
  padding: 10px;
  display: inline-block;
}

/* original width 1516 x height 2240 - 25%*/
.lymph-image-container {
  margin: auto;
  margin-bottom: 15px;
  height: 336px;
  width: 227.4px;
  padding: 10px;
  display: inline-block;
}

/* original width 1075 x height 785 - 35%*/
.phone-image-container-landscape {
  margin: auto;
  margin-bottom: 15px;
  width: 268.75px;
  height: 196.25px;
  padding: 10px;
  display: inline-block;
}

/* original width 1451 x height 1623 - 20%*/
.oncology-image-container {
  margin: auto;
  margin-bottom: 15px;
  height: 243.45px;
  width: 217.65px;
  padding: 10px;
  display: inline-block;
}

/* original width 3232 x height 2721 - 15%*/
.pain-image-container {
  margin: auto;
  margin-bottom: 15px;
  height: 217.68px;
  width: 258.56px;
  padding: 10px;
  display: inline-block;
}

.indian-image-container {
  margin: auto;
  height: 250px;
  width: 100%;
  padding: 10px;
  display: inline-block;
}

.wide-treatment-image {
  height: 100%;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.thin-wide-treatment-image {
  margin: auto;
  margin-top: 20px;
  height: 120px;
  width: 100%;
  padding: 10px;
  display: inline-block;
}

.treatment-grid {
  height: auto;
  justify-content: center;
  margin-top: 5%;
  margin-bottom: 5%;
  display: flex;
  flex-direction: row;
}

.treatment-list {
  text-align: left;
  padding: 0;
  margin: auto;
  list-style-type: none;
}

.treatment-item {
  background: url("../assets/check.png") no-repeat left top;
  padding-left: 44px;
  margin: 20px;
  font-size: 16px;
}

.proper-link {
  color: #036c7b;
}

.treatment-separator {
  border-top: 3px solid #0cb5cf;
  width: 80%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.all-treatments {
  margin-top: 5%;
  margin-bottom: 5%;
  margin: auto;
  width: 90%;
}

.all-treatments-wide {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: auto;
  margin-top: 50px;
  width: 90%;
  flex-wrap: wrap;
  font-family: "Raleway", sans-serif;
  justify-content: center;
}

.all-treatments-page {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: auto;
  margin-top: 50px;
  width: 80%;
  flex-wrap: wrap;
  font-family: "Raleway", sans-serif;
  justify-content: center;
  /* padding-left: 300px;
  padding-top: 100px; */
}

.treatment-tiles {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: auto;
  margin-top: 50px;
  width: 90%;
  flex-wrap: wrap;
  font-family: "Raleway", sans-serif;
}

.all-treatments-home {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: auto;
  width: 80%;
  flex-wrap: wrap;
}

.card {
  flex: 1 1 auto;
}

.card-title {
  font-weight: bold;
  font-size: 16px;
}

.card-text {
  font-size: 15px;
}

ul {
  list-style-position: inside;
  /* width: 600px; */
  max-width: 100%;
  padding: 0;
  margin: 0;
  border-radius: 0.1rem;
}

li {
  padding: 0 0 0 1rem;
}

li:first-child {
  margin-top: 1rem;
}

li:last-child {
  margin-bottom: 1rem;
}

::marker {
  content: "» ";
  font-size: 1.2em;

  --hue: 180;
  color: hsl(var(--hue) 50% 50%);
}

@media screen and (min-device-width: 620px) {
  .menopause-logo {
  margin: auto;
  display: block;
  max-width: 25%;
}

  /* original width 1075 x height 785 - 45%*/
  .phone-image-container-landscape {
    width: 483.75px;
    height: 353.25px;
  }

  .wide-image-container-mediation {
    margin: auto;
    margin-bottom: 25px;
    width: 320px;
    height: 236px;
    padding: 10px;
    display: inline-block;
  }

  /* original width 768 x height 1024 - 20%*/
  .trees-image-container {
    height: 409.6px;
    width: 307.2px;
  }

  /* original width 1080 x height 1205 - 35%*/
  .phone-image-container {
    margin: auto;
    margin-bottom: 15px;
    height: 539.74px;
    width: 483.75px;
    padding: 10px;
    display: inline-block;
  }

  /* original width 1080 x height 1205 - 35%*/
  .ear-phone-image-container {
    margin: auto;
    margin-bottom: 15px;
    height: 387.6px;
    width: 487px;
    padding: 10px;
    display: inline-block;
  }

  .menopause-phone-image-container {
  margin: auto;
  margin-bottom: 15px;
  height: 410px;
  width: 352px;
  padding: 10px;
  display: inline-block;
}

  /* original width 1727 x height 2204 - 20%*/
  .mfr-image-container {
    margin: auto;
    margin-bottom: 15px;
    width: 345.4px;
    height: 440.8px;
    padding: 10px;
    display: inline-block;
  }

  /* original width 1612 x height 1264 - 25%*/
  .mfr2-image-container {
    margin: auto;
    margin-bottom: 15px;
    height: 316px;
    width: 431.75px;
    padding: 10px;
    display: inline-block;
  }

  /* original width 1516 x height 2240 - 25%*/
  .lymph-image-container {
    margin: auto;
    margin-bottom: 15px;
    height: 448px;
    width: 303.2px;
    padding: 10px;
    display: inline-block;
  }

  .pain-image-container {
  margin: auto;
  margin-bottom: 15px;
  height: 408.15px;
  width: 484.8px;
  padding: 10px;
  display: inline-block;
}

  .treatment-heading {
    font-size: 22px;
  }

  .treatment-text {
    font-size: 18px;
  }

  .treatment-text-2 {
    font-size: 18px;
  }

  .all-treatments-wide {
    width: 100%;
  }

  .all-treatments-page {
    width: 100%;
  }

  .treatment-grid {
    width: 60%;
    margin: auto;
  }

  .small-image-container {
    height: 250px;
    width: 250px;
    padding: 20px;
  }

  .wide-image-container {
    height: 250px;
    width: 80%;
  }

  .indian-image-container {
    height: 300px;
    width: 70%;
  }

  .treatment-list {
    margin-top: 20px;
  }

  .treatment-item {
    font-size: 18px;
  }

  .book {
    width: 20%;
  }

  .thin-wide-treatment-image {
    height: 200px;
    width: 100%;
  }

  .card {
    max-width: 45%;
    flex: 0 1 auto;
  }

  .card-title {
    font-size: 18px;
  }

  .card-text {
    font-size: 16px;
  }

  .outer-treatments {
    width: 80%;
  }
}
@media only screen and (min-device-width: 960px) {
  .treatment-grid {
    width: 80%;
    margin: auto;
  }

  .treatment-list {
    width: 80%;
    justify-items: center;
  }

  .small-image-container {
    margin-bottom: 20px;
    height: 250px;
    width: 250px;
    padding: 10px;
  }

  .wide-image-container {
    height: 450px;
    width: 60%;
  }

  .indian-image-container {
    height: 400px;
    max-width: 60%;
  }

  /* .treatment-section {
    width: 80%;
  } */
  .book {
    width: 130px;
  }

  .thin-wide-treatment-image {
    height: 200px;
    width: 100%;
  }

  .all-treatments {
    width: 50%;
  }

  .all-treatments-home {
    width: 50%;
  }

  .flex-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .flex-wrapper-inner {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }
  .accordion-body {
    padding: 40px;
  }
}
@media only screen and (min-device-width: 1200px) {
  .treatment-heading {
    font-size: 25px;
  }

  /* .treatment-text {
    font-size: 20px;
  }

  .treatment-text-2 {
    font-size: 20px;
  } */

  .outer-treatments {
    width: 70%;
    padding-left: 300px;
    padding-top: 100px;
  }

  .all-treatments-page {
    gap: 20px;
    padding-left: 300px;
    padding-top: 100px;
  }

  .treatment-list {
    width: 70%;
  }

  .treatment-grid {
    width: 90%;
    margin: auto;
  }

  .card {
    width: 45%;
    max-width: 400px;
  }

  .card-title {
    font-size: 20px;
    font-weight: bold;
  }

  .card-text {
    font-size: 17px;
  }

  .treatment-section {
    max-width: 700px;
  }

  .indian-image-container {
    height: 450px;
    width: 50%;
  }

  .thin-wide-treatment-image {
    height: 350px;
    width: 100%;
  }

  * {
    box-sizing: border-box;
  }
}
