.outer-grid {
  margin: auto;
}

.condition-grid {
  height: auto;
  justify-content: center;
  margin: auto;
  margin-bottom: 5%;
  padding: 0;
  display: flex;
  flex-direction: row;
  max-width: 600px;
}

.condition-grid-solo {
  height: auto;
  justify-content: center;
  margin: auto;
  margin-bottom: 5%;
  padding: 0;
  display: flex;
  flex-direction: row;
}

.condition-list {
  display: flex;
  flex-direction: column;
  padding: 20px;
  margin: 10px;
  gap: 20px;
  list-style-type: none;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  margin: auto;
  max-width: 100%;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}

.condition-list-solo {
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-left: 0px;
  padding-top: 0px;
  margin: 10px;
  gap: 20px;
  list-style-type: none;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  margin: auto;
  max-width: 100%;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  /* box-shadow: 0.25rem 0.25rem 0.75rem rgb(0 0 0 / 0.15); */
  /* background: #f5f5f5f5; */
}

.condition-list-dual {
  display: flex;
  flex-direction: column;
  padding: 10px;
  /* margin: 10px; */
  gap: 20px;
  list-style-type: none;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
}

.condition-item {
  flex: 1 1 auto;
  width: 100%;
  font-size: 14px;
  align-self: center;
  text-align: center;
  font-weight: 600;
  padding-left: 0px;
}

.backing {
  background-color: #036c7b;
  border-radius: 10px;
}

.treatment-carousel-item {
  font-size: 15px;
}

.outer-large-condition {
  width: 70%;
  margin: auto;
}

@media screen and (min-device-width: 620px) {
  .condition-grid-solo {
    width: 80%;
  }

  .outer-large-condition {
    width: 90%;
    margin: auto;
  }

  .carousel-wrapper {
    margin: auto;
    width: 80%;
    height: 400px;
  }

  .carousel {
    width: 80%;
    height: 400px;
    margin: auto;
  }

  .d-block {
    width: 100%;
    height: 400px;
  }
  .w-100 {
    width: 100%;
    height: 400px;
  }

  .condition-item {
    margin: auto;
    font-size: 16px;
  }

  .treatment-carousel-item {
    font-size: 18px;
  }
}

@media only screen and (min-device-width: 960px) {
  /* .condition-list-solo {
    padding: 40px;
  } */

  .carousel-wrapper {
    margin: auto;
    width: 80%;
    height: 500px;
    align-content: center;
  }

  .carousel {
    margin: auto;
    width: 80%;
    height: 500px;
  }

  .d-block {
    width: 80%;
    height: 500px;
  }
  .w-100 {
    width: 80%;
    height: 500px;
  }

  .treatment-carousel-item {
    font-size: 18px;
  }
  /* .condition-list {
    max-width: 90%;
  } */

  .condition-item {
    font-size: 18px;
  }
}
@media only screen and (min-device-width: 1200px) {
  .condition-list-solo {
    max-width: 700px;
  }

  /* .condition-list {
    max-width: 60%;
  } */

  .carousel-wrapper {
    margin: auto;
    width: 600px;
    height: 600px;
    align-content: center;
  }

  .carousel {
    margin: auto;
    width: 600px;
    height: 600px;
  }

  .d-block {
    width: 600px;
    height: 600px;
  }
  .w-100 {
    width: 600px;
    height: 600px;
  }

  .treatment-carousel-item {
    font-size: 20px;
  }

  .condition-item {
    font-size: 16px;
  }

  .outer-large-condition {
    width: 70%;
    margin: auto;
  }
}
