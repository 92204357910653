.outer-form {
  width: 80%;
  margin: auto;
  margin-bottom: 30px;
  /* padding-left: 300px; */
  /* padding-top: 100px; */
}

.contact-button {
  background-color: #32c1d6;
  margin: auto;
  border-radius: 10px;
  text-align: center;
}

.contact-heading {
  margin-bottom: 25px;
  text-align: center;
  font-size: 20px;
  color: #036c7b;
}
.consent-heading {
  margin-top: 30px;
  font-size: 16px;
  color: #036c7b;
  font-weight: bold;
}

.form-label {
  color: #036c7b;
  font-weight: bold;
}

.contact-button {
  margin: auto;
  width: 35%;
  background-color: #0cb5cf;
  color: black;
  font-weight: bold;
  border: none;
}

@media screen and (min-device-width: 620px) {
  .form-label {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .contact-button {
    margin-top: 15px;
    width: 20%;
  }
}
@media only screen and (min-device-width: 960px) {
  .form-label {
    font-size: 20px;
  }
  .form-check-label {
    font-size: 18px;
  }
  .consent-heading {
    font-size: 20px;
  }

  .form-control::placeholder {
    font-size: 18px;
  }

  .contact-button {
    margin-top: 20px;
    width: 20%;
    font-size: 18px;
  }
}
@media only screen and (min-device-width: 1200px) {

  .outer-form {
    padding-left: 300px;
    padding-top: 100px;
    margin: auto;
    max-width: 1100px;
  }

  .contact-heading {
    font-size: 25px;
  }

  .form-control::placeholder {
    font-size: 20px;
  }

  .contact-button {
    margin-top: 20px;
    width: 20%;
    font-size: 20px;
  }
}
