.outer-review {
  width: 80%;
  margin: auto;
  font-family: "Raleway", sans-serif;
  text-align: center;
  /* padding-left: 300px; */
  /* padding-top: 100px; */
}

.review-heading {
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: center;
  color: #036c7b;
}

.review {
  font-style: italic;
  text-align: center;
  margin-bottom: 40px;
  margin-top: 20px;
}

.review-text {
  font-size: 16px;
  margin-bottom: 10px;
}

.reviewer {
  font-weight: bold;
  color: #036c7b;
  margin-bottom: 20px;
}

@media screen and (min-device-width: 620px) {
  .review-text {
    font-size: 18px;
    margin-bottom: 20px;
  }
}
@media only screen and (min-device-width: 960px) {
  .review {
    margin: auto;
    margin-bottom: 50px;
    width: 65%;
  }
}
@media only screen and (min-device-width: 1200px) {

  .review {
    margin: auto;
    margin-bottom: 50px;
    width: 80%;
    max-width: 700px;
  }

  
  .outer-review {
    padding-left: 300px;
    padding-top: 100px;
  }

  .review-text {
    font-size: 20px;
  }

  .reviewer {
    font-size: 18px;
  }

  .review-heading {
    font-size: 30px;
  }
}
