.outer-about {
  width: 80%;
  margin: auto;
  font-family: "Raleway", sans-serif;
  /* padding-left: 300px; */
  /* padding-top: 100px; */
}
.main-logo-about {
  margin: auto;
  display: block;
  max-width: 80%;
}

.main-heading-about {
  margin-bottom: 15px;
  text-align: center;
  font-size: 25px;
  color: #036c7b;
}

.about-text {
  text-align: center;
  font-size: 16px;
  margin-top: 30px;
}

.privacy-text {
  text-align: left;
  font-size: 16px;
}

.address-text {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 0;
}

.address-text-last {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}

.about-section {
  margin-top: 20px;
  text-align: center;
}

/* fullsize is 464 x 633 */

/* 30% size */
.jacky-image {
  margin: auto;
  margin-bottom: 20px;
  width: 162px;
  height: 222px;
  padding: 10px;
  display: inline-block;
}

@media screen and (min-device-width: 620px) {
  .about-text {
    font-size: 18px;
    margin-top: 30px;
  }

  .privacy-text {
    font-size: 18px;
  }
  

  .address-text {
    font-size: 18px;
  }

  .address-text-last {
    font-size: 18px;
  }

  .jacky-image {
    margin-bottom: 20px;
    width: 185.6px;
    height: 253.2px;
  }
}
@media only screen and (min-device-width: 960px) {
  .about-section {
    width: 80%;
    margin: auto;
  }
}
@media only screen and (min-device-width: 1200px) {
  .outer-about {
    padding-left: 300px;
    padding-top: 100px;
  }

  .jacky-image {
    margin-bottom: 20px;
    width: 232px;
    height: 316px;
  }
  .about-section {
    width: 90%;
    max-width: 700px;
    margin: auto;
  }
}
